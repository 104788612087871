import { Alert, Box, Button, Card, CardContent, CircularProgress, FormControl, FormControlLabel, FormLabel, List, ListItem, ListItemText, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { webApiService } from '../../../services'
import { enqueueSnackbar } from 'notistack'
import { useDetailsContext } from '../context/Details.context'
import { IAppStore } from '../../../models/store.model'
import { useSelector } from 'react-redux'

const Contract: React.FC<any> = () => {
  const { ctx, saveCtx } = useDetailsContext()
  const [loading, setLoading] = useState(false)
  const userState = useSelector((store: IAppStore) => store.user)
  console.log('ctx => ', ctx)

  const handleChangeRUT = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, rut: event.target.value })
  }

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, address: event.target.value })
  }

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, state: event.target.value })
  }

  const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, city: event.target.value })
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, emailRecipient: event.target.value })
  }

  const handleSave = () => {
    setLoading(true)
    webApiService.update({
      stage: 4,
      id: ctx.tradeinDetails.id,
      name: String(ctx.tradeinDetails.name),
      lastname: String(ctx.tradeinDetails.lastname),
      email: ctx.emailRecipient === 'cliente' ? String(ctx.tradeinDetails.email) : 'TIENDA',
      send_type: String(ctx.emailRecipient),
      rut: String(ctx.rut),
      city: ctx.city,
      address: String(ctx.address),
      state: String(ctx.state),
      store_user: String(userState.id),
      sbu: String(ctx.tradeinDetails.sbu),
      brand: ctx.tradeinDetails.wasRecalculated === 1 ? String(ctx.tradeinDetails.make) : String(ctx.tradeinDetails.brand),
      model: ctx.tradeinDetails.wasRecalculated === 1 ? String(ctx.tradeinDetails.modelName) : String(ctx.tradeinDetails.model),
      imei: String(ctx.tradeinDetails.serie),
      discount_amount: ctx.tradeinDetails.wasRecalculated === 1 ? String(ctx.tradeinDetails.new_discount_amount) : String(ctx.tradeinDetails.discount_amount),
      docusign_id: ctx.tradeinDetails.serie + '-' + ctx.tradeinDetails.id,
      phone: ctx.phone
    })
      .then(() => {
        enqueueSnackbar('Registro actualizado', { variant: 'success' })
        saveCtx({ ...ctx, reload: true })
      })
      .catch((error) => {
        enqueueSnackbar('Hubo un error al intentar guardar el registro.', { variant: 'error' })
        saveCtx({ ...ctx, reload: false })
        console.error('error ==>', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Card>
      <CardContent>
        <Typography variant='h6' component='div' gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
          Contrato
        </Typography>
        {
          ctx.displayContract === 0
            ? <Alert severity='info'>No disponible por el momento.</Alert>
            // || configSeller.config.permisos.ENABLE_CHECK_BATTERY === 0
            : ctx.tradeinDetails.isBatteryHealthy === null &&
              (ctx.tradeinDetails.sbu !== 'Tablet' || ctx.tradeinDetails.brand !== 'Apple' || ctx.tradeinDetails.sbu === 'Smartphone')
              ? <Alert severity='info'>Se requiere adjuntar imagen de capacidad de carga del dispositivo (Descuentos) para firmar el contrato.</Alert>
              : (ctx.tradeinDetails.brand === 'Apple' && ctx.tradeinDetails.sbu === 'Smartphone' && ctx.tradeinDetails.isBatteryHealthy === 1 && ctx.tradeinDetails.recalculate === '1' && ctx.tradeinDetails.wasRecalculated === null)
                  ? <Alert severity='warning'>Se requiere recalcular el dispositivo.</Alert>
                  : loading
                    ? <CircularProgress />
                    : (
                        (ctx.tradeinDetails.rut === null && [4].includes(Number(ctx.tradeinDetails.id_status)) && ctx.tradeinDetails.recalculate === '0') ||
              (ctx.tradeinDetails.rut === null && [4].includes(Number(ctx.tradeinDetails.id_status)) && ctx.tradeinDetails.recalculate === '1' && ctx.tradeinDetails.wasRecalculated === 1)
                          ? ctx.tradeinDetails.imgBattery?.length === 0 && (ctx.tradeinDetails.sbu !== 'Tablet' && ctx.tradeinDetails.brand !== 'Apple')
                            ? <Alert severity='info'>Se requiere la validación del estado de la batería para generar el contrato.</Alert>
                            : (
                              <div>
                                <Box mt={2} mb={2} gap={1}>
                                  <TextField
                                    className='search-box'
                                    label={`Ingrese ${String(process.env.REACT_APP_LABEL_RUT)} del cliente`}
                                    variant='outlined'
                                    size='small'
                                    value={ctx.rut}
                                    onChange={handleChangeRUT}
                                  />
                                </Box>
                                <Box mt={2} mb={2} gap={1}>
                                  <TextField
                                    className='search-box'
                                    label='Ingrese Telefono del cliente'
                                    variant='outlined'
                                    size='small'
                                    value={ctx.phone}
                                    onChange={(event) => saveCtx({ ...ctx, phone: event.target.value })}
                                  />
                                </Box>
                                {
                      Number(process.env.REACT_APP_ENABLE_DIR_CONTRACT) === 1
                        ? (
                          <>
                            <Box mt={2} mb={2} gap={1}>
                              <TextField
                                className='search-box'
                                label='Ingrese Dirección'
                                variant='outlined'
                                size='small'
                                value={ctx.address}
                                onChange={handleChangeAddress}
                              />
                            </Box>
                            <Box mt={2} mb={2} gap={1}>
                              <TextField
                                className='search-box'
                                label='Ingrese ciudad'
                                variant='outlined'
                                size='small'
                                value={ctx.city}
                                onChange={handleChangeCity}
                              />
                            </Box>
                            <Box mt={2} mb={2} gap={1}>
                              <TextField
                                className='search-box'
                                label='Ingrese estado'
                                variant='outlined'
                                size='small'
                                value={ctx.state}
                                onChange={handleChangeState}
                              />
                            </Box>
                          </>
                          )
                        : null
                    }

                                <Box mt={2} mb={2} gap={1}>
                                  <FormControl component='fieldset'>
                                    <FormLabel component='legend'>Enviar contrato al correo</FormLabel>
                                    <RadioGroup row aria-label='email-option' name='email-option' value={ctx.emailRecipient} onChange={handleChangeEmail}>
                                      <FormControlLabel value='cliente' control={<Radio />} label='Cliente' />
                                      <FormControlLabel value='modulo' control={<Radio />} label='Módulo' />
                                    </RadioGroup>
                                  </FormControl>
                                </Box>
                                <Box mt={2} mb={2} gap={1}>
                                  <Button variant='contained' size='small' color='primary' onClick={handleSave} disabled={ctx.rut === '' || ctx.emailRecipient === '' || (Number(process.env.REACT_APP_ENABLE_DIR_CONTRACT) === 1 && (ctx.address === '' || ctx.state === '' || ctx.city === ''))}>
                                    Guardar
                                  </Button>
                                </Box>
                                <Alert severity='warning'>Debe ingresar su {String(process.env.REACT_APP_LABEL_RUT)} para generar el contrato.</Alert>
                              </div>
                              )
                          : ctx.tradeinDetails.rut !== null && [3, 4, 5, 7, 8, 9].includes(Number(ctx.tradeinDetails.id_status))
                            ? (
                              <div className='row'>
                                <div className='col-12 col-md-6'>
                                  <List className='py-0'>
                                    <ListItem>
                                      <ListItemText primary='N° identificador de DocuSign' secondary={ctx.tradeinDetails.docusign_id} />
                                    </ListItem>
                                  </List>
                                </div>
                                <div className='col-12 col-md-6 text-end' hidden={ctx.tradeinDetails.url_contract === null || ctx.tradeinDetails.url_contract === ''}>
                                  <Button href={ctx.tradeinDetails.url_contract} variant='contained' size='small' color='primary'>
                                    Descargar
                                  </Button>
                                </div>
                                <div className='col-12'>
                                  <Alert severity='success'>Contrato creado y enviado a: <strong>{ctx.tradeinDetails.contract_email}</strong></Alert>
                                </div>

                              </div>
                              )
                            : <Alert severity='info'>{ctx.tradeinDetails.sbu === 'Smartphone' ? 'Se requiere la validación del certificado de robo para generar el contrato.' : 'Se requiere la validación del checklist para generar el contrato.'} </Alert>
                      )
}
      </CardContent>
    </Card>
  )
}

export default Contract
