import { Button, Switch, Alert, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
// import { styled } from '@mui/material/styles'
import { Accept, useDropzone } from 'react-dropzone'
import { useDetailsContext } from '../context/Details.context'
import { fromImage } from 'imtool'
import { blobToBase64 } from '../../../utils/blobToBase64.util'
import { webApiService } from '../../../services'
import { useParams } from 'react-router-dom'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { enqueueSnackbar } from 'notistack'
import Tooltip from '@mui/material/Tooltip'
import { IAppStore } from '../../../models/store.model'
import { useSelector } from 'react-redux'
import { CurrencyFormat } from '../../../utils/Currency.utility'

const BatteryCapacity = () => {
  const { ctx, saveCtx } = useDetailsContext()
  const userState = useSelector((store: IAppStore) => store.user)
  const [isSelected, setIsSelected] = useState(false)
  console.log('isSelected => ', isSelected)

  const [data, setData] = useState<{ loading: boolean, imagen: string }>({ loading: false, imagen: '' })
  const { slug, id } = useParams()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    saveCtx({
      ...ctx,
      files: [...validFiles]
    })
  }, [ctx, saveCtx])

  const accept: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1
  })
  const removeFile = (file: File, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    saveCtx({
      ...ctx,
      files: ctx.files.filter(f => f !== file)
    })
  }

  const uploadImage = async () => {
    for (const element of ctx.files) {
      if (element.nameFile === undefined) {
        console.log('entro', ctx)
        delete element.preview
        element.blob = await fromImage(element).then((tool: any) => tool.thumbnail(800, false).toBlob())
        element.base64 = await blobToBase64(element.blob)
        delete element.blob
      }
    }
    console.log('🚀 ~ forawait ~ ctx.files:', ctx.files)
    setData({ ...data, loading: true })

    let _discount = ctx.tradeinDetails.new_discount_amount !== null ? ctx.tradeinDetails.new_discount_amount : ctx.tradeinDetails.discount_amount
    console.log('🚀 ~ uploadImage ~ _discount:', _discount)
    if (isSelected) {
      _discount = _discount - Number(process.env.REACT_APP_BATTERY_DISCOUNT)
      _discount = _discount < 0 ? 0 : _discount
      console.log('🚀 ~ :', _discount)
    }

    webApiService.saveFiles({ id, store_user: String(userState.id), files: ctx.files, seller: slug, battery: true, isBatteryHealthy: Number(!isSelected), discount: _discount }).then(() => {
      enqueueSnackbar('Imagen cargada con exito', { variant: 'success' })
      saveCtx({ ...ctx, files: [], reload: true })
      setData({ ...data, loading: false })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  const loadData = () => {
    if (ctx.tradeinDetails.brand === 'Apple' && ctx.tradeinDetails.sbu === 'Smartphone' && ctx.tradeinDetails.isBatteryHealthy === 1) return
    setData({ ...data, loading: true })
    webApiService.getFiles(String(id)).then((res: any) => {
      console.log('🚀 ~ loadData ~ res', res)
      const img = res.data.find((item: any) => item.name.includes('batteryHealth')).name
      console.log('img => ', img)

      setData({ loading: false, imagen: img })
      saveCtx({ ...ctx, tradeinDetails: { ...ctx.tradeinDetails, imgBattery: img } })
      console.log('🚀 ~ loadData ~ res', res.data)
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  const getMonto : (monto: number) => number = (monto) => {
    let _discount = monto
    // if (!discounts.haveCharger || ctx.tradein.haveCharger === 0) {
    //   _discount = _discount + Number(import.meta.env.VITE_CHARGER_DISCOUNT)
    // }
    if (isSelected || ctx.tradeinDetails.isBatteryHealthy === 0) {
      _discount = _discount + Number(process.env.REACT_APP_BATTERY_DISCOUNT)
    }
    if (ctx.tradeinDetails.brand === 'Apple' && ctx.tradeinDetails.sbu === 'Smartphone' && (ctx.tradeinDetails.batteryHealthPercentage !== '' || ctx.tradeinDetails.batteryHealthPercentage != null)) {
      const batteryHealthPercentage = Number(ctx.tradeinDetails.batteryHealthPercentage?.split(' ')[0])

      _discount = batteryHealthPercentage >= 80 ? 0 : _discount + Number(process.env.REACT_APP_BATTERY_DISCOUNT)
    }
    return _discount
  }

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ ctx.isBatteryHealthy', ctx.tradeinDetails.isBatteryHealthy)
    // console.log(data)
    if (ctx.tradeinDetails.isBatteryHealthy !== undefined && ctx.tradeinDetails.isBatteryHealthy !== null) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.tradeinDetails.isBatteryHealthy])

  return (
    <div className='bg-white rounded-lg shadow-lg p-3 h-100'>
      {
      ctx.tradeinDetails.id_status === 1
        ? (
          <div>
            <Typography variant='h6' component='div' gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
              Descuentos
            </Typography>
            <Alert severity='info'>Se requiere el resultado de la evaluación técnica.</Alert>
          </div>
          )
        : (
          <div className='h-100'>
            <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid #f3f1f1' }}>
              <Typography
                variant='h6'
                component='div'
                style={{ fontSize: '18px' }}
              >
                Descuentos
              </Typography>
              <div>Monto {ctx.tradeinDetails.isBatteryHealthy === null ? 'a descontar' : 'descontado'}: <span className='text-primary fw-semibold'>{CurrencyFormat(getMonto(0))}</span></div>
            </div>
            {
  ((ctx.tradeinDetails.status === 'RECHAZADO' && ctx.files.length > 0) || ctx.tradeinDetails.status === 'COTIZADO') && ctx.tradeinDetails.login_code !== ' '
    ? <Alert severity='info'>Se requiere el resultado de la evaluación técnica para cargar la imagen.</Alert>
    : (
        ctx.tradeinDetails.isBatteryHealthy === null
          ? (
            <div className='py-2'>
              <p className='mb-2'>¿Batería menor al 80%?</p>
              <Switch
                checked={isSelected}
                onChange={(event) => setIsSelected(event.target.checked)}
              />
              <div className='pt-2'>
                <div
                  {...getRootProps()}
                  style={{
                    border: '2px dashed #cccccc',
                    borderRadius: '5px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    backgroundColor: isDragActive ? '#cce5ff' : '#ffffff',
                    color: isDragActive ? '#ffffff' : '#000000',
                    display: 'inline-block'
                  }}
                >
                  <input {...getInputProps()} />
                  {
                  isDragActive
                    ? <p>Arrastra y suelta las imágenes aquí...</p>
                    : (
                      <div hidden={ctx.files.length > 0}>
                        <h4 className='font-extrabold' style={{ fontSize: '18px' }}>Adjuntar imagen capacidad de carga del dispositivo</h4>
                        <p>Arrastra y suelta la imagen aquí, o haz clic para seleccionar archivos</p>
                      </div>
                      )
                }
                  <p color='textSecondary' hidden={ctx.files.length > 0}>
                    (Solo se permiten archivos .png, .jpg y .jpeg)
                  </p>
                  <div className='grid grid-cols-1'>
                    {ctx.files.map((file, index) => (
                      <div key={index} className='max-w-44'>
                        <img src={URL.createObjectURL(file)} alt={`preview ${index}`} className='img-fluid' />
                        <p>{file.name}</p>
                        <Tooltip title='Eliminar'>
                          <IconButton onClick={(event) => removeFile(file, event)} aria-label='delete' size='large'>
                            <DeleteIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='mt-2'>
                  <Button
                    component='label'
                    variant='contained'
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon style={{ marginRight: '8px' }} />}
                    onClick={uploadImage}
                    disabled={data.loading || ctx.files.length === 0}
                  >
                    Cargar imagen
                  </Button>
                </div>
              </div>
            </div>
            )
          : null
      )
}
            {
        ctx.tradeinDetails.isBatteryHealthy !== null
          ? (ctx.tradeinDetails.isBatteryHealthy === 1 && ctx.tradeinDetails.batteryHealthPercentage !== '' && ctx.tradeinDetails.batteryHealthPercentage != null)
              ? (
                <div className='d-flex justify-content-center align-items-center h-100'>
                  <div className='d-flex gap-2 align-items-center'>
                    <h6 className='m-0'>Porcentaje de Bateria</h6>
                    <p className='text-primary fw-bold m-0'>{ctx.tradeinDetails.batteryHealthPercentage}</p>
                  </div>
                </div>
                )
              : (
                <div>
                  <p className='text-large'> {ctx.tradeinDetails.isBatteryHealthy === 1 ? 'Mayor ó igual al 80%' : 'No supera el 80%'} </p>
                  <img className='img-fluid' src={`${process.env.REACT_APP_BUCKET}imagenes/${id}/${data.imagen}`} alt={ctx.tradeinDetails.isBatteryHealthy === 1 ? 'Mayor ó igual al 80%' : 'No supera el 80%'} />
                </div>
                )
          : null
      }
          </div>
          )
}
    </div>
  )
}

export default BatteryCapacity
